export const PRIMARY = "#66fcf1";

export const SECONDARY = "#45a29e";

export const BACKGROUND = "#1F2833";

/* Colors used: 
#0B0C10
#1F2833
#C5C6C7
#66FCF1
#45A29E
*/
